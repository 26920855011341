import * as React from "react";
import { Admin, Resource } from "react-admin";
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
} from "react-admin-firebase";
import firebase from "firebase/compat/app";
// Screens
import {
  Dashboard,
  Login,
  ContestCreate,
  ContestEdit,
  ContestList,
  ResultEdit,
  ResultList,
} from "../screens";
// Icons
import ContestIcon from "@mui/icons-material/Article";
import ResultIcon from "@mui/icons-material/EmojiEvents";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const authProvider = FirebaseAuthProvider(firebaseConfig);
const dataProvider = FirebaseDataProvider(firebaseConfig, {
  // All options here https://github.com/benwinding/react-admin-firebase/blob/master/src/providers/options.ts
  logging: process.env.NODE_ENV === "development" ? true : false,
  app: firebaseApp,
  persistence: "local",
  // Prevents document from getting the ID field added as a property
  dontAddIdFieldToDoc: true,
  lazyLoading: {
    enabled: true,
  },
  // Logging of all reads performed by app (additional feature, for lazy-loading testing)
  firestoreCostsLogger: {
    enabled: true,
  },
});

const AdminApp = () => (
  <Admin
    authProvider={authProvider}
    basename="/rianaire"
    dashboard={Dashboard}
    dataProvider={dataProvider}
    disableTelemetry
    loginPage={Login}
    requireAuth
    title="My Custom Admin"
  >
    <Resource
      name="contests"
      list={ContestList}
      edit={ContestEdit}
      create={ContestCreate}
      icon={ContestIcon}
    />

    <Resource
      name="results"
      list={ResultList}
      edit={ResultEdit}
      icon={ResultIcon}
    />
  </Admin>
);

export default AdminApp;
