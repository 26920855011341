import {
  ArrayInput,
  BooleanInput,
  Edit,
  Form,
  required,
  SimpleFormIterator,
  TextField,
  TextInput,
  SaveButton,
  useRecordContext,
} from "react-admin";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Grid } from "@mui/material";

const ContestTitle = () => {
  const record = useRecordContext();
  return <span>Results {record ? `--> ${record.id}` : ""}</span>;
};

const ResultEdit = () => (
  <>
    <Edit title={<ContestTitle />}>
      <Form>
        <Grid
          container
          gap={4}
          sx={{
            marginLeft: 4,
            marginTop: 4,
            marginBottom: 4,
            "& svg": { color: "#93CCFF" },
          }}
        >
          {/* Need to loop over questions and build drop down to select correct answer */}
          <Grid itm xs={10}>
            <ArrayInput label="" source="answers">
              <SimpleFormIterator disableAdd disableRemove disableReordering>
                <TextField label="" source="text" fullWidth />
                <ArrayInput label="" source="options">
                  <TextField source="options" fullWidth />
                </ArrayInput>
                <TextInput
                  label="Result"
                  source="answer"
                  fullWidth
                  validate={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput label="Contest Scored" source="scored" />
          </Grid>

          <Grid itm xs={12}>
            <SaveButton label="Update" icon={<AddBoxIcon />} />
          </Grid>
        </Grid>
      </Form>
    </Edit>
  </>
);

export default ResultEdit;
