import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Pages
import { AdminApp, Home, Support } from "./pages";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route index element={<Home />} />
      <Route path="/support" element={<Support />} />
      <Route path="/rianaire/*" element={<AdminApp />} />
    </Routes>
  </BrowserRouter>
);

export default App;
