import {
  ArrayInput,
  BooleanInput,
  Create,
  DateTimeInput,
  Form,
  NumberInput,
  required,
  TextInput,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
} from "react-admin";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Grid } from "@mui/material";
import { leagues, sports } from "../data";

const ContestCreate = () => (
  <Create>
    <Form>
      <Grid
        container
        gap={4}
        sx={{
          marginLeft: 4,
          marginTop: 4,
          marginBottom: 4,
          "& svg": { color: "#93CCFF" },
        }}
      >
        <Grid itm xs={3}>
          <SelectInput
            source="sport"
            choices={sports}
            fullWidth
            validate={[required()]}
          />
        </Grid>
        <Grid itm xs={3}>
          <SelectInput
            source="league"
            choices={leagues}
            fullWidth
            validate={[required()]}
          />
        </Grid>
        <Grid itm xs={3}>
          <SelectInput
            source="type"
            choices={[
              { id: "Pick", name: "Pick" },
              { id: "Parlay", name: "Parlay" },
            ]}
            fullWidth
            validate={[required()]}
          />
        </Grid>
        <Grid itm xs={3}>
          <TextInput
            source="title"
            multiline
            fullWidth
            validate={[required()]}
          />
        </Grid>
        <Grid itm xs={3}>
          <DateTimeInput
            source="closes"
            fullWidth
            validate={[required()]}
            options={{ timeZone: "America/Chicago" }}
          />
        </Grid>
        <Grid itm xs={3}>
          <NumberInput source="tokens" fullWidth validate={[required()]} />
        </Grid>
        <Grid itm xs={10}>
          <ArrayInput source="questions" validate={[required()]}>
            <SimpleFormIterator>
              <TextInput label="Question" source="text" fullWidth />
              <ArrayInput label="Options" source="options">
                <SimpleFormIterator>
                  <TextInput label="Value" fullWidth />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid itm xs={3}>
          <BooleanInput
            label="Entry Open"
            source="entryOpen"
            defaultValue={false}
            disabled
          />
          <BooleanInput source="completed" defaultValue={false} disabled />
        </Grid>
        <Grid itm xs={12}>
          <SaveButton label="Create" icon={<AddBoxIcon />} />
        </Grid>
      </Grid>
    </Form>
  </Create>
);

export default ContestCreate;
