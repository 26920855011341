import * as React from "react";
import {
  BooleanField,
  EditButton,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

const ContestFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput source="userId" label="User" reference="users">
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

const ResultList = () => (
  <List filters={ContestFilters}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <BooleanField label="Scored" source="scored" />
      <ReferenceField
        label="Contest Open"
        source="contestID"
        reference="contests"
      >
        <BooleanField source="entryOpen" />
      </ReferenceField>
      <ReferenceField
        label="Contest Completed"
        source="contestID"
        reference="contests"
      >
        <BooleanField source="completed" />
      </ReferenceField>
      <TextField label="Contest ID" source="contestID" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ResultList;
