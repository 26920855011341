import React, { useState } from "react";
import { Login } from "react-admin";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import Access from "./Access";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  // signInFlow: "redirect",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/rianaire",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        type: "image", // 'audio'
        size: "normal", // 'invisible' or 'compact'
        badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
      },
      defaultCountry: "US",
    },
  ],
};

const LoginScreen = (props) => {
  const [access, setAccess] = useState(false);

  if (!access) {
    return <Access callBack={setAccess} />;
  }

  return (
    <Login {...props}>
      {/* <h1 style={{ marginLeft: 25 }}>PwnyUp Admin</h1> */}
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </Login>
  );
};

export default LoginScreen;
