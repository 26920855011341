import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

const Dashboard = () => {
  return (
    <Card>
      <CardHeader title="Welcome to PwnyUp " />
      <CardContent>
        Here you have the ability to manage the picks for our application. Below
        details how to create, update and manage picks inside the admin
        dashboard.
      </CardContent>
      <CardContent> CREATING PICKS (Contests Tab) </CardContent>

      <CardContent>
        - To get start click on the "Contests" link in the sidebar.
        <br />
        - Next click on "Create" in the tab right corner
        <br />
        - Fill out all the required data -- Sports
        <br />- Then Click "Create"
        <br />
      </CardContent>

      <CardContent> CLOSING PICK ENTRIES (Contests Tab) </CardContent>

      <CardContent> SCORING PICKS (Results Tab) </CardContent>
      <CardContent>
        To get start click on the "Results" link in the sidebar.
      </CardContent>

      <CardContent> CLOSING PICKS (Contest Tab) </CardContent>
    </Card>
  );
};

export default Dashboard;
