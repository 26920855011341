import React from "react";
import PropTypes from "prop-types";

const Button = ({ onClick, text, styles = "", width = "w-32" }) => {
  return (
    <button
      className={`${styles} font-roboto text-prussian-blue cursor-pointer md:visible rounded-lg bg-blue-200 h-12 text-center inline-block align-middle`}
      style={{
        boxShadow: "0px 4px 0.01px #007DC1",
        width: width,
      }}
      onClick={() => onClick()}
    >
      <p>{text}</p>
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};
export default Button;
