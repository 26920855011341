import * as React from "react";
import {
  ArrayInput,
  BooleanInput,
  Edit,
  DateTimeInput,
  Form,
  NumberInput,
  SaveButton,
  SimpleFormIterator,
  SelectInput,
  required,
  TextInput,
  useRecordContext,
} from "react-admin";
import { Grid } from "@mui/material";
import { leagues, sports } from "../data";

const ContestTitle = () => {
  const record = useRecordContext();
  return (
    <span>Contests --> {record ? `${record.sport} --> ${record.id}` : ""}</span>
  );
};

const ContestEdit = () => {
  return (
    <Edit title={<ContestTitle />}>
      <Form>
        <Grid
          container
          gap={4}
          sx={{
            marginLeft: 4,
            marginTop: 4,
            marginBottom: 4,
            "& svg": { color: "#93CCFF" },
          }}
        >
          <Grid itm xs={3}>
            <SelectInput
              source="sport"
              choices={sports}
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid itm xs={3}>
            <SelectInput
              source="league"
              choices={leagues}
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid itm xs={3}>
            <SelectInput
              source="type"
              choices={[
                { id: "Pick", name: "Pick" },
                { id: "Parlay", name: "Parlay" },
              ]}
              fullWidth
              validate={[required()]}
            />
          </Grid>
          <Grid itm xs={3}>
            <TextInput source="title" fullWidth validate={[required()]} />
          </Grid>
          <Grid itm xs={3}>
            <DateTimeInput
              source="closes"
              fullWidth
              validate={[required()]}
              options={{ timeZone: "America/Chicago" }}
            />
          </Grid>
          <Grid itm xs={3}>
            <NumberInput source="tokens" fullWidth validate={[required()]} />
          </Grid>
          <Grid itm xs={10}>
            <ArrayInput source="questions">
              <SimpleFormIterator>
                <TextInput
                  label="Question"
                  source="text"
                  fullWidth
                  validate={[required()]}
                />
                <ArrayInput source="options">
                  <SimpleFormIterator>
                    <TextInput
                      label="Options"
                      fullWidth
                      validate={[required()]}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
          <Grid itm xs={3}>
            <BooleanInput
              label="Entry Open"
              source="entryOpen"
              defaultValue={false}
            />
            <BooleanInput source="completed" defaultValue={false} />
          </Grid>
          <Grid itm xs={12}>
            <SaveButton label="Update" />
          </Grid>
        </Grid>
      </Form>
    </Edit>
  );
};

export default ContestEdit;
