const sports = [
  { id: "Football", name: "Football" },
  { id: "Basketball", name: "Basketball" },
  { id: "Baseball", name: "Baseball" },
  { id: "Hockey", name: "Hockey" },
  { id: "Golf", name: "Golf" },
  { id: "Soccer", name: "Soccer" },
  { id: "Racing", name: "Racing" },
  { id: "eSports", name: "eSports" },
];
export default sports;
