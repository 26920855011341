import { useRef } from "react";
// Components
import { Button } from "../components";
// Syles
import "../index.css";
// Images
import HeroBg from "../assets/imgs/HeroBackground.png";
import LogoFont from "../assets/imgs/FontLogo.png";
import Logo from "../assets/imgs/Logo.png";
import LogoWhite from "../assets/imgs/LogoWhite.svg";
import Facebook from "../assets/imgs/Facebook.svg";
import Discord from "../assets/imgs/Discord.svg";
import Twitter from "../assets/imgs/Twitter.svg";
import Instagram from "../assets/imgs/Instagram.svg";

function Support() {
  const scrollToEl = useRef(null);

  return (
    <div className="w-screen bg-black overflow-x-hidden">
      <div
        className="w-full no-repeat bg-cover"
        style={{
          backgroundImage: `url(${HeroBg})`,
          height: "850px",
        }}
      >
        {/* Header component */}
        <nav className="flex w-screen pl-8 pr-8 pt-8 justify-items-center items-center">
          <div className="flex flex-row md:w-4/5 self-center items-center">
            <a className="w-screen" href="/">
              <img
                className="hidden md:inline-block"
                src={Logo}
                alt="Logo"
                height="114px"
                width="110px"
              />
              <img
                className="inline-block md:hidden"
                src={Logo}
                alt="Logo"
                height="48px"
                width="55.47px"
              />
              <img
                className="inline-block ml-4"
                src={LogoFont}
                alt="LogoFont"
                height="19px"
                width="121px"
              />
            </a>
          </div>
        </nav>

        {/* Page container */}
        <div className="container mx-auto pl-8 pr-8 md:pl-0 md:pr-0  justify-items-center">
          {/* Hero CTA */}
          <section className="flex flex-wrap pt-28 self-center items-center">
            <div className="w-full md:w-3/5">
              <h1 className="font-roboto text-4xl text-center md:text-left md:text-7xl font-black text-white pb-4">
                Need help?
              </h1>
              <p className="font-roboto text-2xl text-center md:text-left text-white pt-8 md:w-11/12">
                Please reach out to our support team.
              </p>

              <Button
                text="Support"
                onClick={(e) => {
                  window.location.href = "mailto:support@pwnyup.com";
                  e.preventDefault();
                }}
                styles="self-center w-32 mt-4 text-center"
              />
            </div>
          </section>
        </div>

        {/* Footer */}
        <section
          ref={scrollToEl}
          className="h-auto md:columns-2 pl-16 pr-16 mt-16 pt-16"
          style={{ minHeight: "273.01px", backgroundColor: "#1A1C1E" }}
        >
          <div className="w-full">
            <img
              className="inline-block"
              src={LogoWhite}
              alt="Logo"
              height="40px"
              width="251px"
            />
            <h2 className="font-roboto text-2xl font-bold text-white pt-10">
              Social Media
            </h2>
            <section className="flex flex-row flex-wrap pt-2 item-center content-center">
              {/* Add on hover to white 100 */}
              <a
                href="https://twitter.com/pwnyup"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mr-4"
                  src={Twitter}
                  alt="Twitter Icon"
                  height="36px"
                  width="36px"
                />
              </a>
              <a
                href="https://discord.gg/Ty9FYj2QA5"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mr-4"
                  src={Discord}
                  alt="Discord Icon"
                  height="36px"
                  width="36px"
                />
              </a>
              <a href="https://www.instagram.com/pwnyup/">
                <img
                  className="mr-4"
                  src={Instagram}
                  alt="Instagram Icon"
                  height="36px"
                  width="36px"
                />
              </a>
              <a
                href="https://www.facebook.com/pwnyup"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mr-4"
                  src={Facebook}
                  alt="Facebook Icon"
                  height="36px"
                  width="36px"
                />
              </a>
            </section>
          </div>

          <div className="md:text-right mt-10 md:mt-0 pb-10">
            <h2 className="font-roboto text-2xl font-bold text-white">Legal</h2>
            <a
              className="block text-base text-gray-300 pt-2"
              href="https://app.termly.io/document/terms-of-use-for-website/7bc13d49-ff14-4c55-90b8-446d317fb94c"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
            <a
              className="block text-base text-gray-300 pt-2"
              href="https://app.termly.io/document/privacy-policy/f7041742-f428-4765-94b0-35cb8f8125d1"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <span className="block text-base text-gray-300 pt-2">
              &copy; 2022 PwnyUp. All rights reserved.
            </span>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Support;
