import React from "react";
import { useForm } from "react-hook-form";

const Access = ({ callBack }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const { phone, password } = data;
    if (
      phone === process.env.REACT_APP_ADMIN_PHONE ||
      phone === process.env.REACT_APP_ADMIN_PHONE
    ) {
      if (password === process.env.REACT_APP_PASSCODE) {
        callBack(true);
      }
    }
  };

  return (
    <div
      className="flex flex-col justify-center items-center border-2"
      style={{
        width: "300px",
        height: "300px",
        margin: "0 auto",
        marginTop: "200px",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("phone", { required: true })}
          className="border-2"
          style={{
            display: "block",
            marginBottom: "10px",
            height: "30px",
            width: "200px",
          }}
        />
        {/* errors will return when field validation fails  */}
        {errors.phone && (
          <span className="text-red">This field is required</span>
        )}
        <input
          {...register("password", { required: true })}
          className="border-2 mt-4"
          style={{
            display: "block",
            marginBottom: "10px",
            height: "30px",
            width: "200px",
          }}
          type="password"
        />
        {/* errors will return when field validation fails  */}
        {errors.password && (
          <span className="text-red">This field is required</span>
        )}

        <input
          className="bg-anakiwa text-white font-bold py-1 px-4 rounded mt-4"
          type="submit"
          style={{
            display: "block",
            height: "30px",
            width: "200px",
          }}
        />
      </form>
    </div>
  );
};

export default Access;
