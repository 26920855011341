const leagues = [
  { id: "NFL", name: "NFL" },
  { id: "CFB", name: "CFB" },
  { id: "XFL", name: "XFL" },
  { id: "CFL", name: "CFL" },
  { id: "NBA", name: "NBA" },
  { id: "CBB", name: "CBB" },
  { id: "WNBA", name: "WNBA" },
  { id: "NCAAW", name: "NCAAW" },
  { id: "TBT", name: "TBT" },
  { id: "NBA G League", name: "NBA G League" },
  { id: "MLB", name: "MLB" },
  { id: "MILB", name: "MILB" },
  { id: "NHL", name: "NHL" },
  { id: "AHL", name: "AHL" },
  { id: "ECHL", name: "ECHL" },
  { id: "USAA", name: "USAA" },
  { id: "PGA", name: "PGA" },
  { id: "Premier League", name: "Premier League" },
  { id: "EFL Championship", name: "EFL Championship" },
  { id: "EFL League 1", name: "EFL League 1" },
  { id: "EFL League 2", name: "EFL League 2" },
  { id: "MLS", name: "MLS" },
  { id: "MMA", name: "MMA" },
  { id: "Nascar", name: "Nascar" },
  { id: "Indy", name: "Indy" },
  { id: "F1", name: "F1" },
  { id: "NBA 2K", name: "NBA 2K" },
  { id: "Halo", name: "Halo" },
];

export default leagues;
