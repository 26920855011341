import * as React from "react";
import {
  DateField,
  BooleanField,
  EditButton,
  Datagrid,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";

const ContestFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  <ReferenceInput source="userId" label="User" reference="users">
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

const ContestList = () => (
  <List filters={ContestFilters}>
    <Datagrid rowClick="edit">
      <TextField label="ID" source="id" />
      <TextField label="Sport" source="sport" />
      <TextField label="League" source="league" />
      {/* To Do: Add  createdAt ContestCreate 
      <BooleanField label="Created At" source="createdAt" />
      */}
      <TextField label="Title" source="title" />
      <BooleanField label="Completed" source="completed" />
      <BooleanField label="Open" source="entryOpen" />
      {/* To Do: Change to DateField */}
      <DateField
        label="Closes"
        source="closes"
        showTime={true}
        options={{ timeZone: "America/Chicago" }}
      />
      <TextField label="Entrants" source="entrants.length" />
      <TextField label="Tokens" source="tokens" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ContestList;
